<template>
  <div>empty</div>
</template>

<script>
export default {
  name: 'example03',
  props: {},
  data () {
    return {};
  },
  created () {},
  mounted() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
